import moment from "moment";
import React from "react";
import {
    loadFont,
    marketingCreativeColorSchemes,
    marketingCreativeTemplateKeys,
} from "ui/pages/marketing/MarketingCreatives/data";
import { handleDateFormat } from "utils/Utils";

export const WorloyPostSimple = (props) => {
    const { textData, coverImage } = props;

    const defaultColorScheme =
        marketingCreativeColorSchemes?.[marketingCreativeTemplateKeys.Worloy]
            ?.schemes?.[0];

    const color1 = props?.colors?.[0] || defaultColorScheme?.[0];
    const color2 = props?.colors?.[1] || defaultColorScheme?.[1];
    const color3 = props?.colors?.[2] || defaultColorScheme?.[2];
    const color4 = props?.colors?.[3] || defaultColorScheme?.[3];

    const startDateExists = textData?.start_date_time?.exists;
    const startDate = startDateExists && textData?.start_date_time?.date;
    const startTime = startDateExists && textData?.start_date_time?.time;
    const endDateExists = textData?.end_date_time?.exists;
    const endDate = endDateExists && textData?.end_date_time?.date;
    const endTime = endDateExists && textData?.end_date_time?.time;

    React.useEffect(() => {
        loadFont(
            "https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap"
        );
        loadFont(
            "https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap"
        );
        loadFont(
            "https://fonts.googleapis.com/css2?family=Montserrat&display=swap"
        );
    }, []);

    return (
        <div style={{ fontFamily: "Bebas Neue" }}>
            <div
                style={{
                    position: "absolute",
                    top: "95px",
                    left: "49px",
                    wordBreak: "break-word",
                    zIndex: 1,
                    textTransform: "uppercase",
                }}
            >
                {textData?.heading ? (
                    <div
                        style={{
                            color: color3,
                            fontWeight: 400,
                            fontSize: "100px",
                            width: "777px",
                            marginBottom: "35px",
                            lineHeight: "120px",
                        }}
                    >
                        {textData?.heading}
                    </div>
                ) : null}
                {textData?.sub_heading ? (
                    <div
                        style={{
                            color: "white",
                            fontWeight: 400,
                            fontSize: "40px",
                            width: "664px",
                            lineHeight: "48px",
                        }}
                    >
                        {textData?.sub_heading}
                    </div>
                ) : null}
            </div>
            {startDate ? (
                <div
                    style={{
                        position: "absolute",
                        color: "white",
                        backgroundColor: `${color1}99`,
                        top: "529px",
                        left: "49px",
                        fontWeight: 500,
                        fontFamily: "Montserrat",
                        fontSize: "36px",
                        zIndex: 1,
                        paddingLeft: "23px",
                        paddingRight: "23px",
                        width: "626px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.action_text ? (
                        <div
                            style={{
                                width: "100%",
                                height: "77px",
                            }}
                        />
                    ) : null}
                    <div className="d-flex align-items-center mt-3 mb-3">
                        <svg
                            width="54"
                            height="52"
                            style={{ marginRight: "20px" }}
                            viewBox="0 0 54 52"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 12.9999C0 10.5076 0 9.26145 0.535899 8.33325C0.886972 7.72517 1.39192 7.22022 2 6.86915C2.9282 6.33325 4.17436 6.33325 6.66667 6.33325H46.6667C49.159 6.33325 50.4051 6.33325 51.3333 6.86915C51.9414 7.22022 52.4464 7.72517 52.7974 8.33325C53.3333 9.26145 53.3333 10.5076 53.3333 12.9999C53.3333 15.4922 53.3333 16.7384 52.7974 17.6666C52.4464 18.2747 51.9414 18.7796 51.3333 19.1307C50.4051 19.6666 49.159 19.6666 46.6667 19.6666H6.66666C4.17436 19.6666 2.9282 19.6666 2 19.1307C1.39192 18.7796 0.886972 18.2747 0.535899 17.6666C0 16.7384 0 15.4922 0 12.9999Z"
                                fill="white"
                            />
                            <path
                                d="M0 43.6668C0 47.438 0 49.3236 1.17157 50.4952C2.34315 51.6667 4.22876 51.6667 8 51.6667H45.3333C49.1046 51.6667 50.9902 51.6667 52.1618 50.4952C53.3333 49.3236 53.3333 47.438 53.3333 43.6667V33.0001C53.3333 29.2288 53.3333 27.3432 52.1618 26.1717C50.9902 25.0001 49.1046 25.0001 45.3333 25.0001H7.99999C4.22876 25.0001 2.34315 25.0001 1.17157 26.1717C0 27.3432 0 29.2288 0 33.0001V43.6668Z"
                                fill="white"
                            />
                            <path
                                d="M13.332 1L13.332 9"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                            <path
                                d="M40 1L40 9"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                        </svg>
                        <div style={{ position: "relative", top: "7px" }}>
                            {endDate
                                ? handleDateFormat(startDate, false, false) +
                                  " - " +
                                  handleDateFormat(endDate, false, false)
                                : null}
                            {!endDate
                                ? handleDateFormat(startDate, false)
                                : null}
                        </div>
                    </div>
                    {startTime ? (
                        <div className="d-flex align-items-center mb-3">
                            <svg
                                style={{ marginRight: "20px" }}
                                width="53"
                                height="53"
                                viewBox="0 0 53 53"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="26.5"
                                    cy="26.5"
                                    r="23"
                                    stroke="white"
                                    stroke-width="7"
                                />
                                <path
                                    d="M37.7277 27.0905H27.3413C27.2032 27.0905 27.0913 26.9786 27.0913 26.8405V17.636"
                                    stroke="white"
                                    stroke-width="7"
                                    stroke-linecap="round"
                                />
                            </svg>
                            <div style={{ position: "relative", top: "4px" }}>
                                {startTime || null}
                                {endTime ? " - " + endTime : null}
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null}
            {textData?.action_text ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        backgroundColor: "white",
                        top: "490px",
                        left: "49px",
                        fontFamily: "Josefin Sans",
                        fontWeight: 600,
                        fontSize: "36px",
                        display: "flex",
                        zIndex: 2,
                        padding: "21px 23px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.action_text}
                </div>
            ) : null}
            {coverImage ? (
                <div
                    style={{
                        position: "absolute",
                        width: "301px",
                        height: "601px",
                        top: "406px",
                        right: "0px",
                        backgroundImage: "url(" + coverImage + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        clipPath: "ellipse(100% 50% at 100% 50%)",
                    }}
                />
            ) : null}
            {textData?.additional_text ? (
                <div
                    style={{
                        position: "absolute",
                        color: "white",
                        bottom: "42px",
                        left: "49px",
                        fontWeight: 400,
                        textTransform: "uppercase",
                        fontSize: "42px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.additional_text}
                </div>
            ) : null}

            <svg
                width="1080"
                height="1080"
                viewBox="0 0 1080 1080"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_689_3567)">
                    <rect width="1080" height="1080" fill={color4} />
                    <path
                        d="M228 932V864.336L138.5 808.724L49 864.336L49 932L138.5 876.388L228 932Z"
                        fill="white"
                        fill-opacity="0.3"
                    />
                    <path
                        d="M228 763.474V831.138L138.5 775.526L49 831.138L49 763.474L138.5 707.862L228 763.474Z"
                        fill="white"
                        fill-opacity="0.3"
                    />
                    <path
                        d="M228 662.612V730.276L138.5 674.664L49 730.276L49 662.612L138.5 607L228 662.612Z"
                        fill="white"
                        fill-opacity="0.3"
                    />
                    <mask
                        id="path-3-outside-1_689_3567"
                        maskUnits="userSpaceOnUse"
                        x="737"
                        y="364"
                        width="385"
                        height="685"
                        fill={color4}
                    >
                        <rect
                            fill="white"
                            x="737"
                            y="364"
                            width="385"
                            height="685"
                        />
                        <path d="M1079.5 406C1040.04 406 1000.96 413.773 964.504 428.874C928.045 443.976 894.918 466.11 867.014 494.014C839.11 521.918 816.976 555.045 801.874 591.504C786.773 627.962 779 667.038 779 706.5C779 745.962 786.773 785.038 801.874 821.496C816.976 857.955 839.11 891.082 867.014 918.986C894.918 946.89 928.045 969.024 964.504 984.126C1000.96 999.227 1040.04 1007 1079.5 1007L1079.5 706.5V406Z" />
                    </mask>
                    <path
                        d="M1079.5 406C1040.04 406 1000.96 413.773 964.504 428.874C928.045 443.976 894.918 466.11 867.014 494.014C839.11 521.918 816.976 555.045 801.874 591.504C786.773 627.962 779 667.038 779 706.5C779 745.962 786.773 785.038 801.874 821.496C816.976 857.955 839.11 891.082 867.014 918.986C894.918 946.89 928.045 969.024 964.504 984.126C1000.96 999.227 1040.04 1007 1079.5 1007L1079.5 706.5V406Z"
                        stroke={color1}
                        stroke-width="84"
                        mask="url(#path-3-outside-1_689_3567)"
                    />
                    <g opacity="0.7" clip-path="url(#clip1_689_3567)">
                        <path
                            d="M774.5 80.0003C1039.14 546.364 1035.22 661.827 457 372"
                            stroke="url(#paint0_linear_689_3567)"
                            stroke-width="6"
                            stroke-linecap="round"
                        />
                        <path
                            d="M799.5 76.4983C1029.72 508.445 1024.63 627.24 487.284 378.283"
                            stroke="url(#paint1_linear_689_3567)"
                            stroke-width="6"
                            stroke-linecap="round"
                        />
                        <path
                            d="M829 80.0007C1024.81 477.53 1014.05 592.657 517.569 384.569"
                            stroke="url(#paint2_linear_689_3567)"
                            stroke-width="6"
                            stroke-linecap="round"
                        />
                        <path
                            d="M858 82.5017C1019.39 445.614 1003.46 558.073 547.853 390.855"
                            stroke="url(#paint3_linear_689_3567)"
                            stroke-width="6"
                            stroke-linecap="round"
                        />
                        <path
                            d="M887.137 88.1371C1014.11 416.832 992.879 523.486 578.137 397.137"
                            stroke="url(#paint4_linear_689_3567)"
                            stroke-width="6"
                            stroke-linecap="round"
                        />
                        <path
                            d="M917.421 94.4214C1009.98 388.699 982.294 488.9 608.421 403.421"
                            stroke="url(#paint5_linear_689_3567)"
                            stroke-width="6"
                            stroke-linecap="round"
                        />
                        <path
                            d="M947.706 100.706C1005.84 360.566 971.708 454.315 638.706 409.706"
                            stroke="url(#paint6_linear_689_3567)"
                            stroke-width="6"
                            stroke-linecap="round"
                        />
                        <path
                            d="M977.99 106.99C1001.71 332.432 961.123 419.73 668.99 415.99"
                            stroke="url(#paint7_linear_689_3567)"
                            stroke-width="6"
                            stroke-linecap="round"
                        />
                        <path
                            d="M1008.27 113.274C997.577 304.299 950.538 385.145 699.274 422.274"
                            stroke="url(#paint8_linear_689_3567)"
                            stroke-width="6"
                            stroke-linecap="round"
                        />
                        <path
                            d="M1038.56 119.559C993.443 276.166 939.952 350.559 729.559 428.559"
                            stroke="url(#paint9_linear_689_3567)"
                            stroke-width="6"
                            stroke-linecap="round"
                        />
                    </g>
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear_689_3567"
                        x1="851.524"
                        y1="277.225"
                        x2="667.747"
                        y2="461.002"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color={color1} />
                        <stop offset="1" stop-color={color2} />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_689_3567"
                        x1="867.695"
                        y1="269.395"
                        x2="683.919"
                        y2="453.172"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color={color1} />
                        <stop offset="1" stop-color={color2} />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_689_3567"
                        x1="883.867"
                        y1="261.569"
                        x2="700.09"
                        y2="445.346"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color={color1} />
                        <stop offset="1" stop-color={color2} />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_689_3567"
                        x1="900.038"
                        y1="253.741"
                        x2="716.261"
                        y2="437.518"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color={color1} />
                        <stop offset="1" stop-color={color2} />
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear_689_3567"
                        x1="916.209"
                        y1="245.911"
                        x2="732.433"
                        y2="429.688"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color={color1} />
                        <stop offset="1" stop-color={color2} />
                    </linearGradient>
                    <linearGradient
                        id="paint5_linear_689_3567"
                        x1="932.381"
                        y1="238.083"
                        x2="748.604"
                        y2="421.859"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color={color1} />
                        <stop offset="1" stop-color={color2} />
                    </linearGradient>
                    <linearGradient
                        id="paint6_linear_689_3567"
                        x1="948.552"
                        y1="230.254"
                        x2="764.776"
                        y2="414.031"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color={color1} />
                        <stop offset="1" stop-color={color2} />
                    </linearGradient>
                    <linearGradient
                        id="paint7_linear_689_3567"
                        x1="964.724"
                        y1="222.425"
                        x2="780.947"
                        y2="406.202"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color={color1} />
                        <stop offset="1" stop-color={color2} />
                    </linearGradient>
                    <linearGradient
                        id="paint8_linear_689_3567"
                        x1="980.895"
                        y1="214.597"
                        x2="797.118"
                        y2="398.374"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color={color1} />
                        <stop offset="1" stop-color={color2} />
                    </linearGradient>
                    <linearGradient
                        id="paint9_linear_689_3567"
                        x1="997.067"
                        y1="206.768"
                        x2="813.29"
                        y2="390.545"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color={color1} />
                        <stop offset="1" stop-color={color2} />
                    </linearGradient>
                    <clipPath id="clip0_689_3567">
                        <rect width="1080" height="1080" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_689_3567">
                        <rect
                            width="586"
                            height="473"
                            fill="white"
                            transform="translate(1043 540) rotate(-180)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
