import moment from "moment";
import React from "react";
import {
    loadFont,
    marketingCreativeColorSchemes,
    marketingCreativeTemplateKeys,
} from "ui/pages/marketing/MarketingCreatives/data";
import { handleDateFormat } from "utils/Utils";

export const VividplicPostDetailed = (props) => {
    const { textData, coverImage } = props;

    const defaultColorScheme =
        marketingCreativeColorSchemes?.[marketingCreativeTemplateKeys.Vividplic]
            ?.schemes?.[0];

    const color1 = props?.colors?.[0] || defaultColorScheme?.[0];
    const color2 = props?.colors?.[1] || defaultColorScheme?.[1];
    const color3 = props?.colors?.[2] || defaultColorScheme?.[2];

    const startDateExists = textData?.start_date_time?.exists;
    const startDate = startDateExists && textData?.start_date_time?.date;
    const startTime = startDateExists && textData?.start_date_time?.time;
    const endDateExists = textData?.end_date_time?.exists;
    const endDate = endDateExists && textData?.end_date_time?.date;
    const endTime = endDateExists && textData?.end_date_time?.time;

    React.useEffect(() => {
        loadFont(
            "https://fonts.googleapis.com/css2?family=Montserrat&display=swap"
        );
    }, []);

    return (
        <div style={{ fontFamily: "Montserrat" }}>
            <div
                style={{
                    position: "absolute",
                    top: "79px",
                    left: "25px",
                    wordBreak: "break-word",
                }}
            >
                {textData?.heading ? (
                    <div
                        style={{
                            color: color3,
                            fontWeight: 800,
                            fontSize: "72px",
                            width: "876px",
                            marginBottom: "46px",
                        }}
                    >
                        {textData?.heading}
                    </div>
                ) : null}
                {textData?.sub_heading ? (
                    <div
                        style={{
                            color: color2,
                            fontWeight: 600,
                            fontSize: "36px",
                            width: "664px",
                        }}
                    >
                        {textData?.sub_heading}
                    </div>
                ) : null}
            </div>
            {startDate ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: startTime || endTime ? "773px" : "871.5px",
                        left: "48px",
                        fontWeight: 500,
                        fontSize: "36px",
                        width: "664px",
                        whiteSpace: "nowrap",
                    }}
                >
                    <svg
                        style={{
                            position: "absolute",
                            top: "22px",
                            left: "22px",
                        }}
                        width="54"
                        height="52"
                        viewBox="0 0 54 52"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 12.9999C0 10.5076 0 9.26145 0.535899 8.33325C0.886972 7.72517 1.39192 7.22022 2 6.86915C2.9282 6.33325 4.17436 6.33325 6.66667 6.33325H46.6667C49.159 6.33325 50.4051 6.33325 51.3333 6.86915C51.9414 7.22022 52.4464 7.72517 52.7974 8.33325C53.3333 9.26145 53.3333 10.5076 53.3333 12.9999C53.3333 15.4922 53.3333 16.7384 52.7974 17.6666C52.4464 18.2747 51.9414 18.7796 51.3333 19.1307C50.4051 19.6666 49.159 19.6666 46.6667 19.6666H6.66666C4.17436 19.6666 2.9282 19.6666 2 19.1307C1.39192 18.7796 0.886972 18.2747 0.535899 17.6666C0 16.7384 0 15.4922 0 12.9999Z"
                            fill={color3}
                        />
                        <path
                            d="M0 43.6668C0 47.438 0 49.3236 1.17157 50.4952C2.34315 51.6667 4.22876 51.6667 8 51.6667H45.3333C49.1046 51.6667 50.9902 51.6667 52.1618 50.4952C53.3333 49.3236 53.3333 47.438 53.3333 43.6667V33.0001C53.3333 29.2288 53.3333 27.3432 52.1618 26.1717C50.9902 25.0001 49.1046 25.0001 45.3333 25.0001H7.99999C4.22876 25.0001 2.34315 25.0001 1.17157 26.1717C0 27.3432 0 29.2288 0 33.0001V43.6668Z"
                            fill={color3}
                        />
                        <path
                            d="M13.332 1L13.332 9"
                            stroke={color3}
                            stroke-width="2"
                            stroke-linecap="round"
                        />
                        <path
                            d="M40 1L40 9"
                            stroke={color3}
                            stroke-width="2"
                            stroke-linecap="round"
                        />
                    </svg>
                    <div
                        style={{
                            position: "absolute",
                            top: "26px",
                            left: "107px",
                        }}
                    >
                        {endDate
                            ? handleDateFormat(startDate, false, false) +
                              " - " +
                              handleDateFormat(endDate, false, false)
                            : null}
                        {!endDate
                            ? handleDateFormat(startDate, false)
                            : null}
                    </div>
                    {startTime ? (
                        <>
                            <svg
                                style={{
                                    position: "absolute",
                                    top: "124px",
                                    left: "22px",
                                }}
                                width="53"
                                height="53"
                                viewBox="0 0 53 53"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="26.5"
                                    cy="26.5"
                                    r="23"
                                    stroke={color3}
                                    stroke-width="7"
                                />
                                <path
                                    d="M37.7277 27.0905H27.3413C27.2032 27.0905 27.0913 26.9786 27.0913 26.8405V17.636"
                                    stroke={color3}
                                    stroke-width="7"
                                    stroke-linecap="round"
                                />
                            </svg>
                            <div
                                style={{
                                    position: "absolute",
                                    top: "122px",
                                    left: "107px",
                                }}
                            >
                                {startTime || null}
                                {endTime ? " - " + endTime : null}
                            </div>
                        </>
                    ) : null}
                </div>
            ) : null}
            {textData?.name ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "891px",
                        right: "40px",
                        fontWeight: 500,
                        fontSize: "48px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.name}
                </div>
            ) : null}
            {textData?.designation ? (
                <div
                    style={{
                        position: "absolute",
                        color: color1,
                        top: "962px",
                        right: "40px",
                        fontWeight: 800,
                        fontSize: "30px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.designation}
                </div>
            ) : null}
            {textData?.org_name ? (
                <div
                    style={{
                        position: "absolute",
                        color: color1,
                        top: "1010px",
                        right: "40px",
                        fontWeight: 600,
                        fontSize: "30px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.org_name}
                </div>
            ) : null}
            {textData?.additional_text ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "1010px",
                        left: "41px",
                        fontWeight: 600,
                        fontSize: "24px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.additional_text}
                </div>
            ) : null}
            {coverImage ? (
                <div
                    style={{
                        position: "absolute",
                        width: "359px",
                        height: "359px",
                        top: "461px",
                        left: "528px",
                        backgroundImage: "url(" + coverImage + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        borderRadius: "50%",
                    }}
                />
            ) : null}
            {textData?.details_list?.title ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "461px",
                        left: "25px",
                        fontWeight: 800,
                        fontSize: "36px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.details_list?.title}
                </div>
            ) : null}
            {textData?.details_list?.data ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "529px",
                        left: "25px",
                        fontWeight: 400,
                        fontSize: "36px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.details_list?.data?.map((item) => (
                        <div>{item}</div>
                    ))}
                </div>
            ) : null}

            <svg
                style={{
                    position: "absolute",
                    top: "461px",
                    left: "709px",
                }}
                width="179.5"
                height="359"
                viewBox="0 0 309 618"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0 618C40.5785 618 80.7596 610.008 118.249 594.479C155.739 578.95 189.803 556.189 218.496 527.496C247.189 498.803 269.95 464.739 285.479 427.249C301.007 389.76 309 349.578 309 309C309 268.422 301.007 228.24 285.479 190.751C269.95 153.261 247.189 119.197 218.496 90.504C189.803 61.8107 155.739 39.0499 118.249 23.5212C80.7596 7.99251 40.5784 -3.54748e-06 -3.05176e-05 0L0 309L0 618Z"
                    fill={color1}
                    fill-opacity="0.3"
                />
            </svg>
            <svg
                style={{
                    position: "absolute",
                    top: "461px",
                    left: "528px",
                }}
                width="179.5"
                height="359"
                viewBox="0 0 309 618"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M309 0C268.422 0 228.24 7.99249 190.751 23.5212C153.261 39.0499 119.197 61.8107 90.504 90.504C61.8107 119.197 39.0499 153.261 23.5212 190.751C7.99252 228.24 -1.77374e-06 268.422 0 309C1.77374e-06 349.578 7.99252 389.76 23.5212 427.249C39.0499 464.739 61.8107 498.803 90.504 527.496C119.197 556.189 153.261 578.95 190.751 594.479C228.24 610.007 268.422 618 309 618L309 309L309 0Z"
                    fill={color2}
                    fill-opacity="0.3"
                />
            </svg>

            <svg
                width="1080"
                height="1080"
                viewBox="0 0 1080 1080"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_689_1394)">
                    <rect width="1080" height="1080" fill={color1} />
                    <rect x="708" width="372" height="1080" fill={color2} />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24.5 699.732C-5.19536 699.732 -29.2682 723.805 -29.2682 753.5C-29.2682 783.195 -5.19536 807.268 24.5 807.268C54.1954 807.268 78.2682 783.195 78.2682 753.5C78.2682 723.805 54.1954 699.732 24.5 699.732ZM-26.9305 753.5C-26.9305 725.096 -3.90427 702.07 24.5 702.07C52.9043 702.07 75.9305 725.096 75.9305 753.5C75.9305 781.904 52.9043 804.93 24.5 804.93C-3.90427 804.93 -26.9305 781.904 -26.9305 753.5Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24.5 690.965C-10.037 690.965 -38.0348 718.963 -38.0348 753.5C-38.0348 788.037 -10.037 816.035 24.5 816.035C59.037 816.035 87.0348 788.037 87.0348 753.5C87.0348 718.963 59.037 690.965 24.5 690.965ZM-35.697 753.5C-35.697 720.254 -8.7459 693.303 24.5 693.303C57.7459 693.303 84.697 720.254 84.697 753.5C84.697 786.746 57.7459 813.697 24.5 813.697C-8.7459 813.697 -35.697 786.746 -35.697 753.5Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-46.8013 753.5C-46.8013 714.121 -14.8786 682.199 24.5 682.199C63.8786 682.199 95.8013 714.121 95.8013 753.5C95.8013 792.879 63.8786 824.801 24.5 824.801C-14.8786 824.801 -46.8013 792.879 -46.8013 753.5ZM24.5 684.536C-13.5875 684.536 -44.4636 715.412 -44.4636 753.5C-44.4636 791.588 -13.5875 822.464 24.5 822.464C62.5875 822.464 93.4636 791.588 93.4636 753.5C93.4636 715.412 62.5875 684.536 24.5 684.536Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-55.5679 753.5C-55.5679 709.28 -19.7203 673.432 24.5 673.432C68.7203 673.432 104.568 709.28 104.568 753.5C104.568 797.72 68.7203 833.568 24.5 833.568C-19.7203 833.568 -55.5679 797.72 -55.5679 753.5ZM24.5 675.77C-18.4292 675.77 -53.2301 710.571 -53.2301 753.5C-53.2301 796.429 -18.4292 831.23 24.5 831.23C67.4292 831.23 102.23 796.429 102.23 753.5C102.23 710.571 67.4292 675.77 24.5 675.77Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-64.3344 753.5C-64.3344 704.438 -24.5619 664.666 24.5 664.666C73.5619 664.666 113.334 704.438 113.334 753.5C113.334 802.562 73.5619 842.334 24.5 842.334C-24.5619 842.334 -64.3344 802.562 -64.3344 753.5ZM24.5 667.003C-23.2708 667.003 -61.9967 705.729 -61.9967 753.5C-61.9967 801.271 -23.2708 839.997 24.5 839.997C72.2708 839.997 110.997 801.271 110.997 753.5C110.997 705.729 72.2708 667.003 24.5 667.003Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24.5 655.899C-29.4035 655.899 -73.101 699.596 -73.101 753.5C-73.101 807.404 -29.4035 851.101 24.5 851.101C78.4035 851.101 122.101 807.404 122.101 753.5C122.101 699.596 78.4035 655.899 24.5 655.899ZM-70.7632 753.5C-70.7632 700.888 -28.1124 658.237 24.5 658.237C77.1124 658.237 119.763 700.888 119.763 753.5C119.763 806.112 77.1124 848.763 24.5 848.763C-28.1124 848.763 -70.7632 806.112 -70.7632 753.5Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-81.8676 753.5C-81.8676 694.755 -34.2452 647.132 24.5 647.132C83.2452 647.132 130.868 694.755 130.868 753.5C130.868 812.245 83.2452 859.868 24.5 859.868C-34.2452 859.868 -81.8676 812.245 -81.8676 753.5ZM24.5 649.47C-32.9541 649.47 -79.5298 696.046 -79.5298 753.5C-79.5298 810.954 -32.9541 857.53 24.5 857.53C81.9541 857.53 128.53 810.954 128.53 753.5C128.53 696.046 81.9541 649.47 24.5 649.47Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24.5 638.366C-39.0868 638.366 -90.6341 689.913 -90.6341 753.5C-90.6341 817.087 -39.0868 868.634 24.5 868.634C88.0868 868.634 139.634 817.087 139.634 753.5C139.634 689.913 88.0868 638.366 24.5 638.366ZM-88.2964 753.5C-88.2964 691.204 -37.7957 640.704 24.5 640.704C86.7957 640.704 137.296 691.204 137.296 753.5C137.296 815.796 86.7957 866.296 24.5 866.296C-37.7957 866.296 -88.2964 815.796 -88.2964 753.5Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-99.4007 753.5C-99.4007 685.072 -43.9284 629.599 24.5 629.599C92.9285 629.599 148.401 685.072 148.401 753.5C148.401 821.928 92.9285 877.401 24.5 877.401C-43.9284 877.401 -99.4007 821.928 -99.4007 753.5ZM24.5 631.937C-42.6373 631.937 -97.0629 686.363 -97.0629 753.5C-97.0629 820.637 -42.6373 875.063 24.5 875.063C91.6373 875.063 146.063 820.637 146.063 753.5C146.063 686.363 91.6373 631.937 24.5 631.937Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24.5 620.833C-48.7701 620.833 -108.167 680.23 -108.167 753.5C-108.167 826.77 -48.7701 886.167 24.5 886.167C97.7701 886.167 157.167 826.77 157.167 753.5C157.167 680.23 97.7701 620.833 24.5 620.833ZM-105.829 753.5C-105.829 681.521 -47.479 623.171 24.5 623.171C96.479 623.171 154.829 681.521 154.829 753.5C154.829 825.479 96.479 883.829 24.5 883.829C-47.479 883.829 -105.829 825.479 -105.829 753.5Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-116.934 753.5C-116.934 675.388 -53.6117 612.066 24.5 612.066C102.612 612.066 165.934 675.388 165.934 753.5C165.934 831.612 102.612 894.934 24.5 894.934C-53.6117 894.934 -116.934 831.612 -116.934 753.5ZM24.5 614.404C-52.3206 614.404 -114.596 676.679 -114.596 753.5C-114.596 830.321 -52.3206 892.596 24.5 892.596C101.321 892.596 163.596 830.321 163.596 753.5C163.596 676.679 101.321 614.404 24.5 614.404Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24.5 603.3C-58.4533 603.3 -125.7 670.547 -125.7 753.5C-125.7 836.453 -58.4533 903.7 24.5 903.7C107.453 903.7 174.7 836.453 174.7 753.5C174.7 670.547 107.453 603.3 24.5 603.3ZM-123.363 753.5C-123.363 671.838 -57.1622 605.637 24.5 605.637C106.162 605.637 172.363 671.838 172.363 753.5C172.363 835.162 106.162 901.363 24.5 901.363C-57.1622 901.363 -123.363 835.162 -123.363 753.5Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-134.467 753.5C-134.467 665.705 -63.295 594.533 24.5 594.533C112.295 594.533 183.467 665.705 183.467 753.5C183.467 841.295 112.295 912.467 24.5 912.467C-63.295 912.467 -134.467 841.295 -134.467 753.5ZM24.5 596.871C-62.0039 596.871 -132.129 666.996 -132.129 753.5C-132.129 840.004 -62.0039 910.129 24.5 910.129C111.004 910.129 181.129 840.004 181.129 753.5C181.129 666.996 111.004 596.871 24.5 596.871Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24.5 585.767C-68.1366 585.767 -143.233 660.863 -143.233 753.5C-143.233 846.137 -68.1366 921.233 24.5 921.233C117.137 921.233 192.233 846.137 192.233 753.5C192.233 660.863 117.137 585.767 24.5 585.767ZM-140.896 753.5C-140.896 662.154 -66.8455 588.104 24.5 588.104C115.846 588.104 189.896 662.154 189.896 753.5C189.896 844.846 115.846 918.896 24.5 918.896C-66.8455 918.896 -140.896 844.846 -140.896 753.5Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-152 753.5C-152 656.022 -72.9783 577 24.5 577C121.978 577 201 656.022 201 753.5C201 850.978 121.978 930 24.5 930C-72.9783 930 -152 850.978 -152 753.5ZM24.5 579.338C-71.6872 579.338 -149.662 657.313 -149.662 753.5C-149.662 849.687 -71.6872 927.662 24.5 927.662C120.687 927.662 198.662 849.687 198.662 753.5C198.662 657.313 120.687 579.338 24.5 579.338Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <g opacity="0.6">
                        <path
                            d="M907 345C907 378.137 880.137 405 847 405C813.863 405 787 378.137 787 345C787 311.863 813.863 285 847 285C880.137 285 907 311.863 907 345Z"
                            stroke={color1}
                            stroke-width="30"
                        />
                        <circle cx="1012" cy="275" r="20" fill={color1} />
                        <circle
                            cx="1002"
                            cy="447"
                            r="27.5"
                            stroke={color1}
                            stroke-width="5"
                        />
                    </g>
                    <path
                        d="M942.832 622.744C942.832 760.411 839.308 872.012 711.604 872.012"
                        stroke={color1}
                        stroke-width="20"
                    />
                    <path
                        d="M898.342 640.671C898.342 535.366 812.976 450 707.671 450C602.367 450 517 535.366 517 640.671C517 745.976 602.367 831.342 707.671 831.342"
                        stroke={color1}
                        stroke-width="4"
                        stroke-linecap="round"
                    />
                    <path
                        d="M707.671 833.342C708.776 833.342 709.671 832.447 709.671 831.342C709.671 830.238 708.776 829.342 707.671 829.342L707.671 833.342ZM707.671 452L709.671 452L709.671 448L707.671 448L707.671 452ZM707.671 829.342C603.471 829.342 519 744.871 519 640.671L515 640.671C515 747.08 601.262 833.342 707.671 833.342L707.671 829.342ZM519 640.671C519 536.471 603.471 452 707.671 452L707.671 448C601.262 448 515 534.262 515 640.671L519 640.671Z"
                        fill={color2}
                    />
                    <line
                        x1="23"
                        y1="967.094"
                        x2="23"
                        y2="777.001"
                        stroke={color2}
                        stroke-width="4"
                        stroke-linecap="round"
                    />
                    <line
                        x1="22"
                        y1="1045"
                        x2="22"
                        y2="1004"
                        stroke={color2}
                        stroke-width="6"
                        stroke-linecap="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_689_1394">
                        <rect width="1080" height="1080" fill={color3} />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
