import moment from "moment";
import React from "react";
import {
    loadFont,
    marketingCreativeColorSchemes,
    marketingCreativeTemplateKeys,
} from "ui/pages/marketing/MarketingCreatives/data";
import { handleDateFormat } from "utils/Utils";

export const VividplicStoryDetailed = (props) => {
    const { textData, coverImage } = props;

    const defaultColorScheme =
        marketingCreativeColorSchemes?.[marketingCreativeTemplateKeys.Vividplic]
            ?.schemes?.[0];

    const color1 = props?.colors?.[0] || defaultColorScheme?.[0];
    const color2 = props?.colors?.[1] || defaultColorScheme?.[1];
    const color3 = props?.colors?.[2] || defaultColorScheme?.[2];

    const startDateExists = textData?.start_date_time?.exists;
    const startDate = startDateExists && textData?.start_date_time?.date;
    const startTime = startDateExists && textData?.start_date_time?.time;
    const endDateExists = textData?.end_date_time?.exists;
    const endDate = endDateExists && textData?.end_date_time?.date;
    const endTime = endDateExists && textData?.end_date_time?.time;

    React.useEffect(() => {
        loadFont(
            "https://fonts.googleapis.com/css2?family=Montserrat&display=swap"
        );
    }, []);

    return (
        <div style={{ fontFamily: "Montserrat" }}>
            <div
                style={{
                    position: "absolute",
                    top: "151px",
                    left: "44px",
                    wordBreak: "break-word",
                }}
            >
                {textData?.heading ? (
                    <div
                        style={{
                            color: color3,
                            fontWeight: 800,
                            fontSize: "96px",
                            width: "876px",
                            marginBottom: "22px",
                        }}
                    >
                        {textData?.heading}
                    </div>
                ) : null}
                {textData?.sub_heading ? (
                    <div
                        style={{
                            color: color2,
                            fontWeight: 600,
                            fontSize: "40px",
                            width: "664px",
                        }}
                    >
                        {textData?.sub_heading}
                    </div>
                ) : null}
            </div>
            {startDate ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "1667px",
                        left: "44px",
                        fontWeight: 500,
                        fontSize: "36px",
                        width: "664px",
                        height: "193px",
                        whiteSpace: "nowrap",
                    }}
                >
                    <svg
                        style={{
                            position: "absolute",
                            top: "22px",
                            left: "22px",
                        }}
                        width="54"
                        height="52"
                        viewBox="0 0 54 52"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 12.9999C0 10.5076 0 9.26145 0.535899 8.33325C0.886972 7.72517 1.39192 7.22022 2 6.86915C2.9282 6.33325 4.17436 6.33325 6.66667 6.33325H46.6667C49.159 6.33325 50.4051 6.33325 51.3333 6.86915C51.9414 7.22022 52.4464 7.72517 52.7974 8.33325C53.3333 9.26145 53.3333 10.5076 53.3333 12.9999C53.3333 15.4922 53.3333 16.7384 52.7974 17.6666C52.4464 18.2747 51.9414 18.7796 51.3333 19.1307C50.4051 19.6666 49.159 19.6666 46.6667 19.6666H6.66666C4.17436 19.6666 2.9282 19.6666 2 19.1307C1.39192 18.7796 0.886972 18.2747 0.535899 17.6666C0 16.7384 0 15.4922 0 12.9999Z"
                            fill={color3}
                        />
                        <path
                            d="M0 43.6668C0 47.438 0 49.3236 1.17157 50.4952C2.34315 51.6667 4.22876 51.6667 8 51.6667H45.3333C49.1046 51.6667 50.9902 51.6667 52.1618 50.4952C53.3333 49.3236 53.3333 47.438 53.3333 43.6667V33.0001C53.3333 29.2288 53.3333 27.3432 52.1618 26.1717C50.9902 25.0001 49.1046 25.0001 45.3333 25.0001H7.99999C4.22876 25.0001 2.34315 25.0001 1.17157 26.1717C0 27.3432 0 29.2288 0 33.0001V43.6668Z"
                            fill={color3}
                        />
                        <path
                            d="M13.332 1L13.332 9"
                            stroke={color3}
                            stroke-width="2"
                            stroke-linecap="round"
                        />
                        <path
                            d="M40 1L40 9"
                            stroke={color3}
                            stroke-width="2"
                            stroke-linecap="round"
                        />
                    </svg>
                    <div
                        style={{
                            position: "absolute",
                            top: "26px",
                            left: "107px",
                        }}
                    >
                        {endDate
                            ? handleDateFormat(startDate, false, false) +
                              " - " +
                              handleDateFormat(endDate, false, false)
                            : null}
                        {!endDate
                            ? handleDateFormat(startDate, false)
                            : null}
                    </div>
                    {startTime ? (
                        <>
                            <svg
                                style={{
                                    position: "absolute",
                                    top: "124px",
                                    left: "22px",
                                }}
                                width="53"
                                height="53"
                                viewBox="0 0 53 53"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="26.5"
                                    cy="26.5"
                                    r="23"
                                    stroke={color3}
                                    stroke-width="7"
                                />
                                <path
                                    d="M37.7277 27.0905H27.3413C27.2032 27.0905 27.0913 26.9786 27.0913 26.8405V17.636"
                                    stroke={color3}
                                    stroke-width="7"
                                    stroke-linecap="round"
                                />
                            </svg>
                            <div
                                style={{
                                    position: "absolute",
                                    top: "125px",
                                    left: "107px",
                                }}
                            >
                                {startTime || null}
                                {endTime ? " - " + endTime : null}
                            </div>
                        </>
                    ) : null}
                </div>
            ) : null}
            {textData?.action_text ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "1458px",
                        left: "39px",
                        fontWeight: 500,
                        fontSize: "64px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.action_text}
                </div>
            ) : null}
            {textData?.name ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "1668px",
                        right: "36px",
                        fontWeight: 800,
                        fontSize: "64px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.name}
                </div>
            ) : null}
            {textData?.designation ? (
                <div
                    style={{
                        position: "absolute",
                        color: color1,
                        top: "1764px",
                        right: "36px",
                        fontWeight: 800,
                        fontSize: "36px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.designation}
                </div>
            ) : null}
            {textData?.org_name ? (
                <div
                    style={{
                        position: "absolute",
                        color: color1,
                        top: "1812px",
                        right: "36px",
                        fontWeight: 600,
                        fontSize: "36px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.org_name}
                </div>
            ) : null}
            {textData?.additional_text ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "82px",
                        right: "46px",
                        fontWeight: 600,
                        fontSize: "32px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.additional_text}
                </div>
            ) : null}
            {coverImage ? (
                <div
                    style={{
                        position: "absolute",
                        width: "618px",
                        height: "618px",
                        top: "864px",
                        left: "399px",
                        backgroundImage: "url(" + coverImage + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        borderRadius: "50%",
                    }}
                />
            ) : null}
            {textData?.details_list?.title ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "683px",
                        left: "37px",
                        fontWeight: 800,
                        fontSize: "36px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.details_list?.title}
                </div>
            ) : null}
            {textData?.details_list?.data ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "751px",
                        left: "37px",
                        fontWeight: 400,
                        fontSize: "36px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.details_list?.data?.map((item) => (
                        <div>{item}</div>
                    ))}
                </div>
            ) : null}

            <svg
                style={{
                    position: "absolute",
                    top: "864px",
                    left: "708px",
                }}
                width="309"
                height="618"
                viewBox="0 0 309 618"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0 618C40.5785 618 80.7596 610.008 118.249 594.479C155.739 578.95 189.803 556.189 218.496 527.496C247.189 498.803 269.95 464.739 285.479 427.249C301.007 389.76 309 349.578 309 309C309 268.422 301.007 228.24 285.479 190.751C269.95 153.261 247.189 119.197 218.496 90.504C189.803 61.8107 155.739 39.0499 118.249 23.5212C80.7596 7.99251 40.5784 -3.54748e-06 -3.05176e-05 0L0 309L0 618Z"
                    fill={color1}
                    fill-opacity="0.3"
                />
            </svg>
            <svg
                style={{
                    position: "absolute",
                    top: "864px",
                    left: "399px",
                }}
                width="309"
                height="618"
                viewBox="0 0 309 618"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M309 0C268.422 0 228.24 7.99249 190.751 23.5212C153.261 39.0499 119.197 61.8107 90.504 90.504C61.8107 119.197 39.0499 153.261 23.5212 190.751C7.99252 228.24 -1.77374e-06 268.422 0 309C1.77374e-06 349.578 7.99252 389.76 23.5212 427.249C39.0499 464.739 61.8107 498.803 90.504 527.496C119.197 556.189 153.261 578.95 190.751 594.479C228.24 610.007 268.422 618 309 618L309 309L309 0Z"
                    fill={color2}
                    fill-opacity="0.3"
                />
            </svg>

            <svg
                width="1080"
                height="1920"
                viewBox="0 0 1080 1920"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_689_1347)">
                    <rect width="1080" height="1920" fill={color1} />
                    <rect x="708" width="372" height="1920" fill={color2} />
                    <path
                        d="M1131 1138C1131 1389.84 941.618 1594 708.002 1594"
                        stroke={color1}
                        stroke-width="20"
                    />
                    <path
                        d="M1049.61 1170.79C1049.61 978.15 893.448 821.984 700.808 821.984C508.168 821.984 352.002 978.15 352.002 1170.79C352.002 1363.43 508.168 1519.6 700.808 1519.6"
                        stroke={color1}
                        stroke-width="4"
                        stroke-linecap="round"
                    />
                    <path
                        d="M707.806 1519.61C515.166 1519.61 359 1363.45 359 1170.81C359 978.166 515.166 822 707.806 822"
                        stroke={color2}
                        stroke-width="4"
                        stroke-linecap="round"
                    />
                    <line
                        x1="42"
                        y1="1860.09"
                        x2="42"
                        y2="1670"
                        stroke={color2}
                        stroke-width="4"
                        stroke-linecap="round"
                    />
                    <line
                        x1="1053"
                        y1="122"
                        x2="1053"
                        y2="81"
                        stroke={color1}
                        stroke-width="6"
                        stroke-linecap="round"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-0.5 1216.73C-30.1954 1216.73 -54.2682 1240.8 -54.2682 1270.5C-54.2682 1300.2 -30.1954 1324.27 -0.5 1324.27C29.1953 1324.27 53.2682 1300.2 53.2682 1270.5C53.2682 1240.8 29.1953 1216.73 -0.5 1216.73ZM-51.9305 1270.5C-51.9305 1242.1 -28.9043 1219.07 -0.5 1219.07C27.9043 1219.07 50.9305 1242.1 50.9305 1270.5C50.9305 1298.9 27.9043 1321.93 -0.5 1321.93C-28.9043 1321.93 -51.9305 1298.9 -51.9305 1270.5Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-0.5 1207.97C-35.037 1207.97 -63.0348 1235.96 -63.0348 1270.5C-63.0348 1305.04 -35.037 1333.03 -0.5 1333.03C34.037 1333.03 62.0348 1305.04 62.0348 1270.5C62.0348 1235.96 34.037 1207.97 -0.5 1207.97ZM-60.697 1270.5C-60.697 1237.25 -33.7459 1210.3 -0.5 1210.3C32.7459 1210.3 59.697 1237.25 59.697 1270.5C59.697 1303.75 32.7459 1330.7 -0.5 1330.7C-33.7459 1330.7 -60.697 1303.75 -60.697 1270.5Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-71.8013 1270.5C-71.8013 1231.12 -39.8786 1199.2 -0.5 1199.2C38.8786 1199.2 70.8013 1231.12 70.8013 1270.5C70.8013 1309.88 38.8786 1341.8 -0.5 1341.8C-39.8786 1341.8 -71.8013 1309.88 -71.8013 1270.5ZM-0.5 1201.54C-38.5875 1201.54 -69.4636 1232.41 -69.4636 1270.5C-69.4636 1308.59 -38.5875 1339.46 -0.5 1339.46C37.5875 1339.46 68.4636 1308.59 68.4636 1270.5C68.4636 1232.41 37.5875 1201.54 -0.5 1201.54Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-80.5679 1270.5C-80.5679 1226.28 -44.7203 1190.43 -0.5 1190.43C43.7203 1190.43 79.5679 1226.28 79.5679 1270.5C79.5679 1314.72 43.7203 1350.57 -0.5 1350.57C-44.7203 1350.57 -80.5679 1314.72 -80.5679 1270.5ZM-0.5 1192.77C-43.4292 1192.77 -78.2301 1227.57 -78.2301 1270.5C-78.2301 1313.43 -43.4292 1348.23 -0.5 1348.23C42.4292 1348.23 77.2301 1313.43 77.2301 1270.5C77.2301 1227.57 42.4292 1192.77 -0.5 1192.77Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-89.3344 1270.5C-89.3344 1221.44 -49.5619 1181.67 -0.5 1181.67C48.5619 1181.67 88.3344 1221.44 88.3344 1270.5C88.3344 1319.56 48.5619 1359.33 -0.5 1359.33C-49.5619 1359.33 -89.3344 1319.56 -89.3344 1270.5ZM-0.5 1184C-48.2708 1184 -86.9967 1222.73 -86.9967 1270.5C-86.9967 1318.27 -48.2708 1357 -0.5 1357C47.2708 1357 85.9967 1318.27 85.9967 1270.5C85.9967 1222.73 47.2708 1184 -0.5 1184Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-0.5 1172.9C-54.4035 1172.9 -98.101 1216.6 -98.101 1270.5C-98.101 1324.4 -54.4035 1368.1 -0.5 1368.1C53.4035 1368.1 97.101 1324.4 97.101 1270.5C97.101 1216.6 53.4035 1172.9 -0.5 1172.9ZM-95.7632 1270.5C-95.7632 1217.89 -53.1124 1175.24 -0.5 1175.24C52.1124 1175.24 94.7632 1217.89 94.7632 1270.5C94.7632 1323.11 52.1124 1365.76 -0.5 1365.76C-53.1124 1365.76 -95.7632 1323.11 -95.7632 1270.5Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-106.868 1270.5C-106.868 1211.75 -59.2452 1164.13 -0.5 1164.13C58.2452 1164.13 105.868 1211.75 105.868 1270.5C105.868 1329.25 58.2452 1376.87 -0.5 1376.87C-59.2452 1376.87 -106.868 1329.25 -106.868 1270.5ZM-0.5 1166.47C-57.9541 1166.47 -104.53 1213.05 -104.53 1270.5C-104.53 1327.95 -57.9541 1374.53 -0.5 1374.53C56.9541 1374.53 103.53 1327.95 103.53 1270.5C103.53 1213.05 56.9541 1166.47 -0.5 1166.47Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-0.5 1155.37C-64.0868 1155.37 -115.634 1206.91 -115.634 1270.5C-115.634 1334.09 -64.0868 1385.63 -0.5 1385.63C63.0868 1385.63 114.634 1334.09 114.634 1270.5C114.634 1206.91 63.0868 1155.37 -0.5 1155.37ZM-113.296 1270.5C-113.296 1208.2 -62.7957 1157.7 -0.5 1157.7C61.7957 1157.7 112.296 1208.2 112.296 1270.5C112.296 1332.8 61.7957 1383.3 -0.5 1383.3C-62.7957 1383.3 -113.296 1332.8 -113.296 1270.5Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-124.401 1270.5C-124.401 1202.07 -68.9285 1146.6 -0.5 1146.6C67.9285 1146.6 123.401 1202.07 123.401 1270.5C123.401 1338.93 67.9285 1394.4 -0.5 1394.4C-68.9285 1394.4 -124.401 1338.93 -124.401 1270.5ZM-0.5 1148.94C-67.6373 1148.94 -122.063 1203.36 -122.063 1270.5C-122.063 1337.64 -67.6373 1392.06 -0.5 1392.06C66.6373 1392.06 121.063 1337.64 121.063 1270.5C121.063 1203.36 66.6373 1148.94 -0.5 1148.94Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-0.5 1137.83C-73.7701 1137.83 -133.167 1197.23 -133.167 1270.5C-133.167 1343.77 -73.7701 1403.17 -0.5 1403.17C72.7701 1403.17 132.167 1343.77 132.167 1270.5C132.167 1197.23 72.7701 1137.83 -0.5 1137.83ZM-130.829 1270.5C-130.829 1198.52 -72.479 1140.17 -0.5 1140.17C71.479 1140.17 129.829 1198.52 129.829 1270.5C129.829 1342.48 71.479 1400.83 -0.5 1400.83C-72.479 1400.83 -130.829 1342.48 -130.829 1270.5Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-141.934 1270.5C-141.934 1192.39 -78.6117 1129.07 -0.5 1129.07C77.6117 1129.07 140.934 1192.39 140.934 1270.5C140.934 1348.61 77.6117 1411.93 -0.5 1411.93C-78.6117 1411.93 -141.934 1348.61 -141.934 1270.5ZM-0.5 1131.4C-77.3206 1131.4 -139.596 1193.68 -139.596 1270.5C-139.596 1347.32 -77.3206 1409.6 -0.5 1409.6C76.3206 1409.6 138.596 1347.32 138.596 1270.5C138.596 1193.68 76.3206 1131.4 -0.5 1131.4Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-0.5 1120.3C-83.4534 1120.3 -150.7 1187.55 -150.7 1270.5C-150.7 1353.45 -83.4534 1420.7 -0.5 1420.7C82.4534 1420.7 149.7 1353.45 149.7 1270.5C149.7 1187.55 82.4534 1120.3 -0.5 1120.3ZM-148.363 1270.5C-148.363 1188.84 -82.1623 1122.64 -0.5 1122.64C81.1622 1122.64 147.363 1188.84 147.363 1270.5C147.363 1352.16 81.1622 1418.36 -0.5 1418.36C-82.1623 1418.36 -148.363 1352.16 -148.363 1270.5Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-159.467 1270.5C-159.467 1182.71 -88.295 1111.53 -0.5 1111.53C87.295 1111.53 158.467 1182.71 158.467 1270.5C158.467 1358.29 87.295 1429.47 -0.5 1429.47C-88.295 1429.47 -159.467 1358.29 -159.467 1270.5ZM-0.5 1113.87C-87.0039 1113.87 -157.129 1184 -157.129 1270.5C-157.129 1357 -87.0039 1427.13 -0.5 1427.13C86.0039 1427.13 156.129 1357 156.129 1270.5C156.129 1184 86.0039 1113.87 -0.5 1113.87Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-0.5 1102.77C-93.1366 1102.77 -168.233 1177.86 -168.233 1270.5C-168.233 1363.14 -93.1366 1438.23 -0.5 1438.23C92.1366 1438.23 167.233 1363.14 167.233 1270.5C167.233 1177.86 92.1366 1102.77 -0.5 1102.77ZM-165.896 1270.5C-165.896 1179.15 -91.8455 1105.1 -0.5 1105.1C90.8455 1105.1 164.896 1179.15 164.896 1270.5C164.896 1361.85 90.8455 1435.9 -0.5 1435.9C-91.8455 1435.9 -165.896 1361.85 -165.896 1270.5Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M-177 1270.5C-177 1173.02 -97.9783 1094 -0.5 1094C96.9783 1094 176 1173.02 176 1270.5C176 1367.98 96.9783 1447 -0.5 1447C-97.9783 1447 -177 1367.98 -177 1270.5ZM-0.5 1096.34C-96.6872 1096.34 -174.662 1174.31 -174.662 1270.5C-174.662 1366.69 -96.6872 1444.66 -0.5 1444.66C95.6871 1444.66 173.662 1366.69 173.662 1270.5C173.662 1174.31 95.6871 1096.34 -0.5 1096.34Z"
                        fill={color2}
                        fill-opacity="0.8"
                    />
                    <g opacity="0.6">
                        <path
                            d="M892 683C892 716.137 865.137 743 832 743C798.863 743 772 716.137 772 683C772 649.863 798.863 623 832 623C865.137 623 892 649.863 892 683Z"
                            stroke={color1}
                            stroke-width="30"
                        />
                        <circle cx="997" cy="613" r="20" fill={color1} />
                        <circle
                            cx="987"
                            cy="785"
                            r="27.5"
                            stroke={color1}
                            stroke-width="5"
                        />
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_689_1347">
                        <rect width="1080" height="1920" fill={color3} />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
