import moment from "moment";
import React from "react";
import {
    loadFont,
    marketingCreativeColorSchemes,
    marketingCreativeTemplateKeys,
} from "ui/pages/marketing/MarketingCreatives/data";
import { handleDateFormat } from "utils/Utils";

export const WorloyStorySimple = (props) => {
    const { textData, coverImage } = props;

    const defaultColorScheme =
        marketingCreativeColorSchemes?.[marketingCreativeTemplateKeys.Worloy]
            ?.schemes?.[0];

    const color1 = props?.colors?.[0] || defaultColorScheme?.[0];
    const color2 = props?.colors?.[1] || defaultColorScheme?.[1];
    const color3 = props?.colors?.[2] || defaultColorScheme?.[2];
    const color4 = props?.colors?.[3] || defaultColorScheme?.[3];

    const startDateExists = textData?.start_date_time?.exists;
    const startDate = startDateExists && textData?.start_date_time?.date;
    const startTime = startDateExists && textData?.start_date_time?.time;
    const endDateExists = textData?.end_date_time?.exists;
    const endDate = endDateExists && textData?.end_date_time?.date;
    const endTime = endDateExists && textData?.end_date_time?.time;

    React.useEffect(() => {
        loadFont(
            "https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap"
        );
        loadFont(
            "https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap"
        );
        loadFont(
            "https://fonts.googleapis.com/css2?family=Montserrat&display=swap"
        );
    }, []);

    return (
        <div style={{ fontFamily: "Bebas Neue" }}>
            <div
                style={{
                    position: "absolute",
                    top: "147px",
                    left: "78px",
                    wordBreak: "break-word",
                    zIndex: 1,
                    textTransform: "uppercase",
                }}
            >
                {textData?.heading ? (
                    <div
                        style={{
                            color: color3,
                            fontWeight: 400,
                            fontSize: "120px",
                            width: "876px",
                            marginBottom: "11px",
                            lineHeight: "144px",
                        }}
                    >
                        {textData?.heading}
                    </div>
                ) : null}
                {textData?.sub_heading ? (
                    <div
                        style={{
                            color: "white",
                            fontWeight: 400,
                            fontSize: "40px",
                            width: "664px",
                            lineHeight: "48px",
                        }}
                    >
                        {textData?.sub_heading}
                    </div>
                ) : null}
            </div>
            {startDate ? (
                <div
                    style={{
                        position: "absolute",
                        color: "white",
                        backgroundColor: `${color1}99`,
                        top: "675px",
                        left: "78px",
                        fontWeight: 500,
                        fontFamily: "Montserrat",
                        fontSize: "36px",
                        zIndex: 1,
                        paddingLeft: "23px",
                        paddingRight: "23px",
                        width: "626px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.action_text ? (
                        <div
                            style={{
                                width: "100%",
                                height: "77px",
                            }}
                        />
                    ) : null}
                    <div className="d-flex align-items-center mt-3 mb-3">
                        <svg
                            width="54"
                            height="52"
                            style={{ marginRight: "20px" }}
                            viewBox="0 0 54 52"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 12.9999C0 10.5076 0 9.26145 0.535899 8.33325C0.886972 7.72517 1.39192 7.22022 2 6.86915C2.9282 6.33325 4.17436 6.33325 6.66667 6.33325H46.6667C49.159 6.33325 50.4051 6.33325 51.3333 6.86915C51.9414 7.22022 52.4464 7.72517 52.7974 8.33325C53.3333 9.26145 53.3333 10.5076 53.3333 12.9999C53.3333 15.4922 53.3333 16.7384 52.7974 17.6666C52.4464 18.2747 51.9414 18.7796 51.3333 19.1307C50.4051 19.6666 49.159 19.6666 46.6667 19.6666H6.66666C4.17436 19.6666 2.9282 19.6666 2 19.1307C1.39192 18.7796 0.886972 18.2747 0.535899 17.6666C0 16.7384 0 15.4922 0 12.9999Z"
                                fill="white"
                            />
                            <path
                                d="M0 43.6668C0 47.438 0 49.3236 1.17157 50.4952C2.34315 51.6667 4.22876 51.6667 8 51.6667H45.3333C49.1046 51.6667 50.9902 51.6667 52.1618 50.4952C53.3333 49.3236 53.3333 47.438 53.3333 43.6667V33.0001C53.3333 29.2288 53.3333 27.3432 52.1618 26.1717C50.9902 25.0001 49.1046 25.0001 45.3333 25.0001H7.99999C4.22876 25.0001 2.34315 25.0001 1.17157 26.1717C0 27.3432 0 29.2288 0 33.0001V43.6668Z"
                                fill="white"
                            />
                            <path
                                d="M13.332 1L13.332 9"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                            <path
                                d="M40 1L40 9"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                        </svg>
                        <div style={{ position: "relative", top: "7px" }}>
                            {endDate
                                ? handleDateFormat(startDate, false, false) +
                                  " - " +
                                  handleDateFormat(endDate, false, false)
                                : null}
                            {!endDate
                                ? handleDateFormat(startDate, false)
                                : null}
                        </div>
                    </div>
                    {startTime ? (
                        <div className="d-flex align-items-center mb-3">
                            <svg
                                style={{ marginRight: "20px" }}
                                width="53"
                                height="53"
                                viewBox="0 0 53 53"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="26.5"
                                    cy="26.5"
                                    r="23"
                                    stroke="white"
                                    stroke-width="7"
                                />
                                <path
                                    d="M37.7277 27.0905H27.3413C27.2032 27.0905 27.0913 26.9786 27.0913 26.8405V17.636"
                                    stroke="white"
                                    stroke-width="7"
                                    stroke-linecap="round"
                                />
                            </svg>
                            <div style={{ position: "relative", top: "4px" }}>
                                {startTime || null}
                                {endTime ? " - " + endTime : null}
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null}
            {textData?.action_text ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        backgroundColor: "white",
                        top: "636px",
                        left: "78px",
                        fontFamily: "Josefin Sans",
                        fontWeight: 600,
                        fontSize: "36px",
                        display: "flex",
                        zIndex: 2,
                        padding: "21px 23px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.action_text}
                </div>
            ) : null}
            {coverImage ? (
                <div
                    style={{
                        position: "absolute",
                        width: "502px",
                        height: "1003px",
                        top: "917px",
                        right: "0px",
                        backgroundImage: "url(" + coverImage + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        clipPath: "ellipse(100% 50% at 100% 50%)",
                    }}
                />
            ) : null}
            {textData?.additional_text ? (
                <div
                    style={{
                        position: "absolute",
                        color: "white",
                        bottom: "30px",
                        left: "30px",
                        fontWeight: 400,
                        fontSize: "42px",
                        textTransform: "uppercase",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.additional_text}
                </div>
            ) : null}

            <svg
                width="1080"
                height="1920"
                viewBox="0 0 1080 1920"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_689_3692)">
                    <rect width="1080" height="1920" fill={color4} />
                    <mask
                        id="path-2-outside-1_689_3692"
                        maskUnits="userSpaceOnUse"
                        x="536"
                        y="875"
                        width="586"
                        height="1087"
                        fill={color4}
                    >
                        <rect
                            fill="white"
                            x="536"
                            y="875"
                            width="586"
                            height="1087"
                        />
                        <path d="M1079.5 917C1013.64 917 948.429 929.972 887.584 955.174C826.739 980.377 771.455 1017.32 724.886 1063.89C678.317 1110.45 641.377 1165.74 616.174 1226.58C590.972 1287.43 578 1352.64 578 1418.5C578 1484.36 590.972 1549.57 616.174 1610.42C641.377 1671.26 678.317 1726.55 724.886 1773.11C771.455 1819.68 826.74 1856.62 887.584 1881.83C948.429 1907.03 1013.64 1920 1079.5 1920L1079.5 1418.5V917Z" />
                    </mask>
                    <path
                        d="M1079.5 917C1013.64 917 948.429 929.972 887.584 955.174C826.739 980.377 771.455 1017.32 724.886 1063.89C678.317 1110.45 641.377 1165.74 616.174 1226.58C590.972 1287.43 578 1352.64 578 1418.5C578 1484.36 590.972 1549.57 616.174 1610.42C641.377 1671.26 678.317 1726.55 724.886 1773.11C771.455 1819.68 826.74 1856.62 887.584 1881.83C948.429 1907.03 1013.64 1920 1079.5 1920L1079.5 1418.5V917Z"
                        stroke={color1}
                        stroke-width="84"
                        mask="url(#path-2-outside-1_689_3692)"
                    />
                    <g opacity="0.7" clip-path="url(#clip1_689_3692)">
                        <path
                            d="M811.5 245C1076.14 711.364 1072.22 826.827 494 537"
                            stroke="url(#paint0_linear_689_3692)"
                            stroke-width="6"
                            stroke-linecap="round"
                        />
                        <path
                            d="M836.5 241.498C1066.72 673.445 1061.63 792.24 524.284 543.283"
                            stroke="url(#paint1_linear_689_3692)"
                            stroke-width="6"
                            stroke-linecap="round"
                        />
                        <path
                            d="M866 245.001C1061.81 642.53 1051.05 757.657 554.569 549.569"
                            stroke="url(#paint2_linear_689_3692)"
                            stroke-width="6"
                            stroke-linecap="round"
                        />
                        <path
                            d="M895 247.502C1056.39 610.614 1040.46 723.073 584.853 555.855"
                            stroke="url(#paint3_linear_689_3692)"
                            stroke-width="6"
                            stroke-linecap="round"
                        />
                        <path
                            d="M924.137 253.137C1051.11 581.832 1029.88 688.486 615.137 562.137"
                            stroke="url(#paint4_linear_689_3692)"
                            stroke-width="6"
                            stroke-linecap="round"
                        />
                        <path
                            d="M954.421 259.421C1046.98 553.699 1019.29 653.9 645.421 568.421"
                            stroke="url(#paint5_linear_689_3692)"
                            stroke-width="6"
                            stroke-linecap="round"
                        />
                        <path
                            d="M984.706 265.706C1042.84 525.566 1008.71 619.315 675.706 574.706"
                            stroke="url(#paint6_linear_689_3692)"
                            stroke-width="6"
                            stroke-linecap="round"
                        />
                        <path
                            d="M1014.99 271.99C1038.71 497.432 998.123 584.73 705.99 580.99"
                            stroke="url(#paint7_linear_689_3692)"
                            stroke-width="6"
                            stroke-linecap="round"
                        />
                        <path
                            d="M1045.27 278.274C1034.58 469.299 987.538 550.145 736.274 587.274"
                            stroke="url(#paint8_linear_689_3692)"
                            stroke-width="6"
                            stroke-linecap="round"
                        />
                        <path
                            d="M1075.56 284.559C1030.44 441.166 976.952 515.559 766.559 593.559"
                            stroke="url(#paint9_linear_689_3692)"
                            stroke-width="6"
                            stroke-linecap="round"
                        />
                    </g>
                    <path
                        d="M309 1578V1490.77L193.5 1419.07L78 1490.77L78 1578L193.5 1506.3L309 1578Z"
                        fill="white"
                        fill-opacity="0.3"
                    />
                    <path
                        d="M309 1360.73V1447.97L193.5 1376.27L78 1447.97L78 1360.73L193.5 1289.03L309 1360.73Z"
                        fill="white"
                        fill-opacity="0.3"
                    />
                    <path
                        d="M309 1230.7V1317.93L193.5 1246.23L78 1317.93L78 1230.7L193.5 1159L309 1230.7Z"
                        fill="white"
                        fill-opacity="0.3"
                    />
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear_689_3692"
                        x1="888.524"
                        y1="442.225"
                        x2="704.747"
                        y2="626.002"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color={color1} />
                        <stop offset="1" stop-color={color2} />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_689_3692"
                        x1="904.695"
                        y1="434.395"
                        x2="720.919"
                        y2="618.172"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color={color1} />
                        <stop offset="1" stop-color={color2} />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_689_3692"
                        x1="920.867"
                        y1="426.569"
                        x2="737.09"
                        y2="610.346"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color={color1} />
                        <stop offset="1" stop-color={color2} />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_689_3692"
                        x1="937.038"
                        y1="418.741"
                        x2="753.261"
                        y2="602.518"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color={color1} />
                        <stop offset="1" stop-color={color2} />
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear_689_3692"
                        x1="953.209"
                        y1="410.911"
                        x2="769.433"
                        y2="594.688"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color={color1} />
                        <stop offset="1" stop-color={color2} />
                    </linearGradient>
                    <linearGradient
                        id="paint5_linear_689_3692"
                        x1="969.381"
                        y1="403.083"
                        x2="785.604"
                        y2="586.859"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color={color1} />
                        <stop offset="1" stop-color={color2} />
                    </linearGradient>
                    <linearGradient
                        id="paint6_linear_689_3692"
                        x1="985.552"
                        y1="395.254"
                        x2="801.776"
                        y2="579.031"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color={color1} />
                        <stop offset="1" stop-color={color2} />
                    </linearGradient>
                    <linearGradient
                        id="paint7_linear_689_3692"
                        x1="1001.72"
                        y1="387.425"
                        x2="817.947"
                        y2="571.202"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color={color1} />
                        <stop offset="1" stop-color={color2} />
                    </linearGradient>
                    <linearGradient
                        id="paint8_linear_689_3692"
                        x1="1017.9"
                        y1="379.597"
                        x2="834.118"
                        y2="563.374"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color={color1} />
                        <stop offset="1" stop-color={color2} />
                    </linearGradient>
                    <linearGradient
                        id="paint9_linear_689_3692"
                        x1="1034.07"
                        y1="371.768"
                        x2="850.29"
                        y2="555.545"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color={color1} />
                        <stop offset="1" stop-color={color2} />
                    </linearGradient>
                    <clipPath id="clip0_689_3692">
                        <rect width="1080" height="1920" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_689_3692">
                        <rect
                            width="586"
                            height="473"
                            fill="white"
                            transform="translate(1080 705) rotate(-180)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
